<template>
  <div class="">

    <a-input-group compact>
      <a-input disabled style="width:40%;" v-model="lng" placeholder="纬度"></a-input>
      <a-input disabled style="width: 40%;" v-model="lat" placeholder="经度"></a-input>
      <a-button type="primary" style="width: 20%;margin-right:0" icon="global" @click="showBaiduMap"></a-button>
    </a-input-group>
    <a-modal :maskClosable="false" title="定位坐标" style="top: 8px;" :width="1000" v-model="visible" :footer="null"
      @cancel="closeBaiduMap">
      <baidu-map class="baidu-map" ak="MaGIfq1f3oQtEy4W6YR0lG5wkAntGHdH" :center="lat?{lat:lat,lng:lng}:city" :zoom="20"
        :scroll-wheel-zoom="true" @click="getPosition">
        <!-- <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-scale> -->
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-marker :position="{lat:lat,lng:lng}" :dragging="true"></bm-marker>
        <!-- <bm-local-search :keyword="keyword" :auto-viewport="true" :location="location"></bm-local-search> -->
      </baidu-map>
    </a-modal>
  </div>
</template>

<script>
  import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
  import {
    BmScale,
    BmNavigation,
    BmMarker,
    BmLocalSearch
  } from 'vue-baidu-map'
  const qs=require("qs")
  import {
    jsonp
  } from 'vue-jsonp'
  export default {
    data() {
      return {
        visible: false,
        // baiduPoint: {
        //   lng: 116.404,
        //   lat: 39.915
        // },
      }
    },
    components: {
      BaiduMap,
      BmScale,
      BmNavigation,
      BmMarker,
      BmLocalSearch
    },
    // props: ["lat", "lng"],
    props: {
      "city": {
        default: "上海"
      },
      lat: {
        default: '' //31.213
      },
      lng: {
        default: '' //121.445
      },
    },
    methods: {
      getPointByName(address) {
        const data = {
          address: address,
          // ak: 'MaGIfq1f3oQtEy4W6YR0lG5wkAntGHdH' // 指方向提供的key，显示不可用，下面的key是许文的，暂时用一下
          ak: 'Sjg7NUzHU5dN5DF6F2l79zZY2W2PIDUL'
        }
        this.axios.post(`/api/base/system/map/getAddress?${qs.stringify(data)}`).then(res => {
          let tmp=res.body.result
          if(tmp){
           this.$emit("update:lat", tmp.location.lat)
           this.$emit("update:lng", tmp.location.lng) 
          }
        }).catch(err => console.log(err))
      },
      showBaiduMap() {
        this.visible = true
      },
      closeBaiduMap() {
        this.visible = false
      },
      /**
       * 获取坐标
       */
      getPosition(e) {
        this.$emit("update:lat", e.point.lat)
        this.$emit("update:lng", e.point.lng)
        this.$emit("change")
      }
    },
    created() {

    }
  }
</script>

<style lang="less" scoped>
  .baidu-map {
    width: 100%;
    height: 600px;
  }

  .ant-btn {
    margin-bottom: 0px;
  }
</style>
